import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Logo = ({ dark }) => (
  (dark 
    ? <StaticImage src="./logo.png" alt="Kunsági Borvidék" />
    : <StaticImage src="./logo_white.png" alt="Kunsági Borvidék" />)
  
)

export default Logo
