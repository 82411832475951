/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from "react"
import { Global } from "@emotion/react"
import Wrapper from "./layout-wrapper"
import Sidebar from "./sidebar"
import Footer from "./footer"
import Seo from "./seo"
import { SkipNavTarget, SkipNavTrigger } from "./skip-nav"
import { AllPages } from "../interfaces/allPages"
import { Page } from "../interfaces/page"
import "lightgallery.js/dist/css/lightgallery.css";
import { LightgalleryProvider } from "react-lightgallery";
import '../fonts/typography.css'

type LayoutProps = {
  children: React.ReactNode;
  color?: string,
  allPages: AllPages,
  page?: Page
}

const Layout = ({ children, color = `white`, allPages, page }: LayoutProps) => (
  <React.Fragment>
    <LightgalleryProvider>
      <Global
        styles={(theme) => ({
          "*,*:after,*:before": {
            boxSizing: `border-box`,
          },
          html: {
            fontSize: `18px`,
            WebkitTextSizeAdjust: `100%`,
          },
          img: {
            borderStyle: `none`,
          },
          pre: {
            fontFamily: `monospace`,
            fontSize: `1em`,
          },
          "[hidden]": {
            display: `none`,
          },
          "::selection": {
            background: theme.colors.primary,
            color: theme.colors.white,
          },
          "ul > li > code, ol > li > code, p > code": {
            color: `#393A34`,
            background: `#f6f8fa`,
            padding: 2,
          },
          "@media(max-width: 600px)": {
            html: {
              fontSize: `16px`,
            },
          },
          "p": {
            textAlign: "justify",
          }
        })}
      />
      <Seo />
      <SkipNavTrigger />
      <Wrapper>
        <Sidebar bg={color} allPages={allPages} page={page} />
        <main sx={{ gridColumnStart: [1, 1, 1, 2] }}>
          <SkipNavTarget />
          {children}
        </main>
        <Footer bg={color} />
      </Wrapper>
    </LightgalleryProvider>
  </React.Fragment>
)

export default Layout
