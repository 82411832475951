/** @jsx jsx */
import { jsx, Link as TLink } from "theme-ui"
import * as React from "react"
import { Link } from "gatsby"
import { readableColor } from "polished"
import { AllPages } from "../interfaces/allPages"
import sortPages from "../utils/sort-pages"
import { characterOccurrence } from "../utils/character_occurrence"
import { Page } from "../interfaces/page"
import HamburgerMenu from 'react-hamburger-menu'

const Navigation = ({ bg, allPages, page }: { bg: string, allPages: AllPages, page?: Page }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const navigation = allPages.pages.nodes
    .sort(sortPages)
    .map(({ title, slug, hierarchy }) => ({
    name: title,
    slug,
    hierarchy
  }))

  const menuClicked = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  const currentNavItem = page?.slug  
    ? navigation
        .find(navItem => navItem.slug === page.slug)
    : null

  function shouldDisplayMenuItem(navItem: {
    name: string,
    slug: string,
    hierarchy: string,
  }): string {
    if (navItem.hierarchy.length === 1) return 'block';
    if (!currentNavItem && navItem.hierarchy.length !== 1) {
      return 'none';
    }
    const currentHierarchy = currentNavItem.hierarchy.substring(0, 1);
    if (navItem.hierarchy.startsWith(currentHierarchy)) { // part of the subtree

      // Show levels one below the current selected
      if (navItem.hierarchy.split(".").length - 1 === currentNavItem.hierarchy.split(".").length && navItem.hierarchy.startsWith(currentNavItem.hierarchy)) {
        return 'block';
      }

      // Also show levels above current selected
      if (navItem.hierarchy.split(".").length < currentNavItem.hierarchy.split(".").length) {
        return 'block';
      }
      
      // Show same level as well, but only if its from the same parent
      if (navItem.hierarchy.split(".").length === currentNavItem.hierarchy.split(".").length && navItem.hierarchy.startsWith(currentNavItem.hierarchy.substring(0, currentNavItem.hierarchy.lastIndexOf(".")))) {
        return 'block'
      }
      return 'none'
    }
    else {
      if (navItem.hierarchy.length === 1) return 'block'
      return 'none';
    }
  }

  return (
    <nav
      style={{ overflow: "auto", minHeight: "20px" }}
      aria-label="Primary Navigation"
      className={`navbar-wrapper`}
      sx={{
        width: "100%",
        marginBottom: ["0px", "0px", "0px", "316px"],
        marginLeft: ["-170px", "-170px", "-170px", "0"],
        a: {
          color: readableColor(bg),
          textDecoration: `none`,
          fontSize: [1, 2, 2, 3],
          marginLeft: [2, 3, 3, 0],
          "&:hover,&:focus": {
            color: readableColor(bg, `primary`, `primaryLight`, false),
          },
        },
        ul: {
          margin: 0,
          padding: 0,
          li: {
            listStyle: `none`,
            display: [`inline-block`, `inline-block`, `inline-block`, `block`],
          },
        },
        variant: `navigation`,
      }}
    >
      <div
        sx={{
          display: ["flex", "flex", "flex", "none"],
          alignItems: "flex-end",
          justifyContent: "flex-end",
          margin: "0.3rem",
          marginBottom: "4rem",
          ".hamburger-menu-button:hover": {
            cursor: "pointer",
          }
        }}
      >
        <HamburgerMenu
          className="hamburger-menu-button"
          isOpen={isMenuOpen}
          menuClicked={menuClicked}
          width={25}
          height={25}
          strokeWidth={1}
          rotate={0}
          color={readableColor(bg)}
          borderRadius={0}
          animationDuration={0.5}
        />
      </div>
      <ul sx={{
        display: [
          isMenuOpen ? "block" : "none",
          isMenuOpen ? "block" : "none",
          isMenuOpen ? "block" : "none",
          "block"
        ]
      }} >
        {navigation.map((navItem) => (
          <li 
            sx={{
              a: {
                color: currentNavItem?.slug === navItem.slug
                  ? readableColor(bg, `primary`, `primaryLight`, false)
                  : readableColor(bg),
              }
            }}
            style={{
              marginLeft: `${15 * characterOccurrence(navItem.hierarchy, ".")}px`,
              display: shouldDisplayMenuItem(navItem),
            }}
          key={navItem.slug}>
            <TLink 
            sx={{
              ":hover": { color: "#7BA428 !important" },
            }}
            style={{
              fontSize: `${1 - characterOccurrence(navItem.hierarchy, ".") * 0.1}rem`,
            }} as={Link} to={navItem.slug}>
              {navItem.name}
            </TLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Navigation
