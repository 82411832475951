/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { readableColor } from "polished"
import Logo from "../icons/logo"
import useSiteMetadata from "../hooks/use-site-metadata"
import Navigation from "./navigation"
import { AllPages } from "../interfaces/allPages"
import { Page } from "../interfaces/page"

type SidebarProps = { bg: string, allPages: AllPages, page?: Page }

const Sidebar = ({ bg, allPages, page }: SidebarProps) => {
  const { siteTitle } = useSiteMetadata()

  return (
    <header
      sx={{
        p: [3, 3, 4],
        width: (t: any): any => [`100%`, `100%`, `100%`, t.sidebar.normal, t.sidebar.normal],
        backgroundColor: bg,
        position: `relative`,
        height: `100%`,
        display: `flex`,
        flexDirection: [`row`, `row`, `row`, `column`],
        alignItems: `flex-start`,
        justifyContent: [`space-between`, `space-between`, `space-between`, `flex-start`],
        svg: {
          fill: readableColor(bg),
        },
        variant: `sidebar`,
      }}
      data-testid="sidebar"
    >
      <Link to="/" aria-label={`${siteTitle}, Back to Home`} sx={{ width: [`3rem`, `4rem`, `4.5rem`, `5rem`] }}>
        <Logo dark={bg !== "#0c403e"} />
      </Link>
      <div sx={{ py: 4, display: [`none`, `none`, `none`, `block`] }} />
      <Navigation bg={bg} allPages={allPages} page={page} />
    </header>
  )
}

export default Sidebar
