/** @jsx jsx */
import { jsx, Link, useThemeUI } from "theme-ui"
import { padding, readableColor } from "polished"
import useSiteMetadata from "../hooks/use-site-metadata"
import { StaticImage } from "gatsby-plugin-image"


const Footer = ({ bg }: { bg: string }) => {
  const { siteTitle } = useSiteMetadata()
  const {
    theme: { rawColors },
  } = useThemeUI()

  const text = readableColor(
    bg,
    rawColors!.textMuted as string | undefined,
    rawColors!.textMutedLight as string | undefined
  )

  return (
    <footer
      sx={{
        position: [`relative`, `relative`, `relative`, `absolute`],
        width: (t: any): any => [`100%`, `100%`, `100%`, t.sidebar.normal, t.sidebar.normal],
        bottom: 0,
        color: bg !== "#d7da05"
          ? "#000"
          : "#fff",
        fontSize: 0,
        p: [2, 2, 3],
        background: bg !== "#d7da05"
          ? "#d7da05"
          : "#0c403e",
        a: {
          color: readableColor(bg),
          "&:hover,&:focus": {
            color: readableColor(bg, `primary`, `primaryLight`, false),
          },
        },
        variant: `footer`,
      }}
    >
      <div 
        sx={{
          display: "flex",
        }}
        style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>

        <div style={{ padding: "10px 0" }}>
          <StaticImage height={80} src="./images/magyar_bor.png" alt="Magyar Bor Személyesen" />
        </div>
        
        <div style={{ padding: "10px 0" }}>
          <StaticImage height={60} src="./images/wim_logo.png" alt="Wine in Moderation" />
        </div>

      </div>
        <div style={{ textTransform: "uppercase", fontSize: "0.75rem", marginBottom: "0.5rem", fontWeight: 500 }}>
          Kövess minket Facebookon és Instagramon
        </div>
        <div style={{
          display: "flex",
          flexDirection: "row",
        }}>
          <a target="__blank" href="https://www.instagram.com/igazikunsag/">
            {
              bg !== "#d7da05"
                ? <StaticImage width={30} height={30} style={{ marginRight: "0.5rem" }} src="../icons/ig.png" alt="Instagram" />
                : <StaticImage width={30} height={30} style={{ marginRight: "0.5rem" }} src="../icons/ig_white.png" alt="Instagram" />
            }
          </a>

          <a target="__blank" href="https://www.facebook.com/igazikunsag">
          {
            bg !== "#d7da05"
              ? <StaticImage width={30} height={30} style={{ marginRight: "0.5rem" }} src="../icons/fb.png" alt="Facebook" />
              : <StaticImage width={30} height={30} style={{ marginRight: "0.5rem" }} src="../icons/fb_white.png" alt="Facebook" />
          }
          </a>

          <a target="__blank" href="mailto:kunsagiborvidekht@gmail.com">
          {
            bg !== "#d7da05"
              ? <StaticImage width={30} height={30} style={{ marginRight: "0.5rem" }} src="../icons/mail.png" alt="Email" />
              : <StaticImage width={30} height={30} style={{ marginRight: "0.5rem" }} src="../icons/mail_white.png" alt="Email" />
          }
          </a>
        </div>
        
    </footer>
  )
}

export default Footer
