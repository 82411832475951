import getPathFromName from "../../utils/get-path-from-name";

const sortPages = (a, b) => {
  // Wineries has to be sorted in ABC order
  if (a.hierarchy && b.hierarchy && a.hierarchy.startsWith("4.") && b.hierarchy.startsWith("4.")) {
    if (getPathFromName(a.title) < getPathFromName(b.title)) { return -1; }
    if (getPathFromName(a.title) > getPathFromName(b.title)) { return 1; }
    return 0;
  }
  
  if (a.hierarchy && !b.hierarchy) return -1;
  if (b.hierarchy && !a.hierarchy) return 1;
  if (!a.hierarchy && !b.hierarchy) return 0;
  if (
    (a.hierarchy.split(".").length === 3 && b.hierarchy.split(".").length === 3)
  ) {
    const aPrefix = a.hierarchy.substring(0, 3);
    const bPrefix = b.hierarchy.substring(0, 3);
    if (`${aPrefix}_${a.name}` < `${bPrefix}_${b.name}`) return -1;
    if (`${bPrefix}_${b.name}` < `${aPrefix}_${a.name}`) return 1;
    return 0;
  }

  if (a.hierarchy.startsWith("4.") && b.hierarchy.startsWith("4.")) {
    if (a.name < b.name) return -1;
    if (b.name < a.name) return 1;
    return 0;
  }

  if(a.hierarchy < b.hierarchy) { return -1; }
  if(a.hierarchy > b.hierarchy) { return 1; }
  return 0;
}

export default sortPages;